export default {
  phone: [
    {
      required: true,
      message: "请输入手机号",
    },
    {
      pattern:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      message: "请检查手机号格式",
    },
  ],

  domicile: {
    required: true,
    message: "请选择户籍所在地",
  },
  highTalentType: {
    required: true,
    message: "请输入才类别",
  },
  nation: {
    required: true,
    message: "请选择民族",
  },
  politicCountenance: {
    required: true,
    message: "请选择政治面貌",
  },
  workCmpy: {
    required: true,
    message: "请输入工作单位",
  },
  subsidyType: {
    required: true,
    message: "请选择补贴类型",
  },
  leaseTerm: {
    required: true,
    message: "请选择租赁期限",
  },
  family: {
    type: "array",
    required: true,
    defaultField: {
      type: "object",
      fields: {
        familyTies: [
          {
            required: true,
            message: "请选择家庭成员关系",
          },
        ], //关系
        familyName: [
          {
            required: true,
            message: "请输入家庭成员姓名",
          },
          {
            max: 100,
            message: "最多100字",
          },
        ], //姓名
        famIdCardType: [
          {
            required: true,
            message: "请输入家庭成员证件类型",
          },
        ],
        famIdCardNum: [
          {
            required: true,
            message: "请输入家庭成员身份证号",
          },
          {
            pattern:
              /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
            message: "家庭成员身份证号格式不正确",
          },
        ],
        phone: [
          {
            pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
            message: "家庭成员手机号格式不正确",
          },
        ],
      },
    },
  },
}