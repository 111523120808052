<template>
  <!--
  *@des: 高层次人才免租住房
  -->
  <section class="form-wrapper">
    <div>
      <div class="notices">
        <p class="notice-item">*填写说明</p>
        <p class="notice-item">1、家庭成员须依据实际情况填写配偶及未成年子女</p>
        <p class="notice-item">
          2、申请人需在港区工作或创业并完整填写在工商局注册的单位全称
        </p>
      </div>
      <van-form ref="form">
        <van-field
          v-model="form.applyName"
          name="applyName"
          label="用户名"
          placeholder="请输入姓名"
          required
        />
        <van-field-select
          v-model="form.idCardType"
          :options="idCardTypes"
          :fieldProps="{
            label: '证件类型',
            required: true,
            placeholder: '请选择证件类型',
          }"
        ></van-field-select>
        <div style="display: flex">
          <van-field
            v-model="form.idCardNum"
            name="idCardNum"
            label="证件号码"
            placeholder="请输入证件号码"
            required
          />
          <van-button class="idCardNum-button" @click="gccrcsbhy"
            >查询证书</van-button
          >
        </div>
        <van-field
          v-model="form.phone"
          name="phone"
          label="手机号"
          placeholder="请输入手机号"
          required
          maxlength="11"
        />
        <van-field-area
          v-model="form.domicile"
          name="domicile"
          :fieldProps="{
            required: true,
            label: '户籍地址',
            placeholder: '请选择户籍地址',
          }"
          defaultField=""
        />
        <van-field
          v-model="form.highTalentType"
          name="highTalentType"
          label="高层次人才类别"
          placeholder="请输入高层次人才类别"
          required
          :disabled="hasHighTalent"
        />
        <van-field-select
          v-model="form.nation"
          :options="nations"
          :fieldProps="{
            label: '民族',
            required: true,
            placeholder: '请选择民族',
          }"
        ></van-field-select>
        <van-field-select
          v-model="form.politicCountenance"
          :options="politics"
          :fieldProps="{
            label: '政治面貌',
            required: true,
            placeholder: '请选择政治面貌',
          }"
        ></van-field-select>

        <van-field
          v-model="form.workCmpy"
          name="workCmpy"
          label="工作单位"
          placeholder="请输入工作单位"
          required
          maxlength="100"
        />

        <van-field-select
          v-model="form.subsidyType"
          :options="subsidyType"
          :fieldProps="{
            label: '补贴类型',
            required: true,
            placeholder: '请选择补贴类型',
          }"
        ></van-field-select>

        <van-field-select
          v-if="'免租住房' == form.subsidyType"
          v-model="form.leaseTerm"
          :options="leaseTerms"
          :fieldProps="{
            label: '租赁期限',
            required: true,
            placeholder: '请选择租赁期限',
          }"
        ></van-field-select>

        <!-- 家庭关系 -->
        <div>
          <van-divider
            content-position="left"
            :style="{
              color: '#1989fa',
              borderColor: '#1989fa',
              padding: '0rem 0.3rem',
            }"
            >家庭关系</van-divider
          >
          <div v-for="(item, i) in form.family" :key="i">
            <van-field-select
              v-model="item.familyTies"
              :options="familyTies"
              :fieldProps="{
                label: '关系',
                required: true,
                placeholder: '请选择关系',
              }"
            ></van-field-select>
            <van-field
              v-model="item.familyName"
              name="familyName"
              label="姓名"
              placeholder="请输入姓名"
              maxlength="20"
              required
            />
            <van-field
              v-model="item.famIdCardType"
              required
              name="famIdCardType"
              label="证件类型"
              placeholder="请输入证件类型"
              maxlength="100"
              disabled
            />
            <van-field
              v-model="item.famIdCardNum"
              name="famIdCardNum"
              label="身份证号"
              required
              placeholder="请输入身份证号"
              maxlength="18"
            />
            <van-field
              v-model="item.phone"
              label="联系方式"
              placeholder="请输入联系方式"
              maxlength="100"
            />
            <p class="fam-btns">
              <van-button class="btn" type="default" size="mini" @click="onAdd">
                新增
              </van-button>
              <van-button
                class="btn"
                type="danger"
                size="mini"
                @click="onDelete(i)"
              >
                删除
              </van-button>
            </p>
          </div>
        </div>
      </van-form>
    </div>
  </section>
</template>

<script>
import formMixin from "../mixin";
import Schema from "async-validator";
import { nations } from "../config";
import { gccrcsbhy } from "@/api/policy/apply";
import formRules from "./rules";
export default {
  name: "HighFreeRent",
  mixins: [formMixin],
  data() {
    return {
      politics: [
        { value: "中共党员(含预备党员)", label: "中共党员(含预备党员)" },
        { value: "民主党派", label: "民主党派" },
        { value: "无党派人士", label: "无党派人士" },
        { value: "团员", label: "团员" },
        { value: "群众", label: "群众" },
      ],
      leaseTerms: [
        { value: "1年", label: "1年" },
        { value: "2年", label: "2年" },
        { value: "3年", label: "3年" },
      ],
      nations,
      form: {
        applyName: "", //姓名
        idCardType: "居民身份证", //证件类型
        phone: "",
        idCardNum: "", //证件号码
        domicile: [], //户籍地址
        workCmpy: "", //工作单位
        highTalentType: "", //高层次人才类别
        politicCountenance: "", //政治面貌
        subsidyType: "",
        leaseTerm: "", //租赁年限
        nation: "",
        family: [
          {
            familyTies: "",
            familyName: "",
            famIdCardType: "居民身份证",
            famIdCardNum: "",
            phone: "",
          },
        ],
      },
      formRules: formRules,
      idCardTypes: [
        {
          value: "居民身份证",
          label: "居民身份证",
        },
        {
          value: "护照",
          label: "护照",
        },
      ],
      subsidyType: [
        {
          value: "免租住房",
          label: "免租住房",
        },
        {
          value: "租房补贴",
          label: "租房补贴",
        },
      ],
      //家庭关系
      familyTies: [
        {
          value: "配偶",
          label: "配偶",
        },
        {
          value: "儿子",
          label: "儿子",
        },
        {
          label: "女儿",
          value: "女儿",
        },
      ],
      hasHighTalent: true,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.global.userInfo;
    },
  },

  created() {},
  methods: {
    gccrcsbhy() {
      if (
        !this.form.applyName ||
        !this.form.idCardNum ||
        !this.form.idCardType
      ) {
        this.$toast("用户名与证件号码不能为空");
        return;
      }
      const idCardRule =
        /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;
      if (
        "居民身份证" == this.form.idCardType &&
        !idCardRule.test(this.form.idCardNum)
      ) {
        this.$toast("证件号格式不正确");
        return;
      }
      gccrcsbhy({
        perCard: this.form.idCardNum,
        perName: this.form.applyName,
        type: "居民身份证" == this.form.idCardType ? "1" : "0",
      })
        .then((res) => {
          let { success, data } = res;
          if (success) {
            this.form.highTalentType = data[0].endCertificateName;
          } else {
            this.$toast("暂无高层次人才认证,请手动输入高层次人才类别");
            this.hasHighTalent = false;
          }
        })
        .catch((e) => {
          this.$toast("暂无高层次人才认证,请手动输入高层次人才类别");
          this.hasHighTalent = false;
        });
    },
    /*
     *@des: 新增家庭成员
     */
    onAdd() {
      this.form.family.push({
        familyTies: "",
        familyName: "",
        famIdCardType: "居民身份证",
        famIdCardNum: "",
        famDomicile: [],
      });
    },
    /*
     *@des: 删除家庭成员
     */
    onDelete(index) {
      if (this.form.family.length === 1) {
        this.$toast("最后一条不能删除");
        return;
      }
      this.form.family.splice(index, 1);
    },
    /*
     *@des: 初始化校验
     */
    initFormValidator() {
      const rules={
        ...this.formRules
      }
      const idCardRule = [
        {
          required: true,
          message: "请输入证件号",
          trigger: "blur",
        },
        {
          pattern:
            /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
          message: "证件号格式不正确",
          trigger: "blur",
        },
      ];
      const passPortRule = {
        required: true,
        message: "请输入证件号",
        trigger: "blur",
      };
      if ("居民身份证" == this.form.idCardType) {
        rules.idCardNum = idCardRule;
      } else {
        rules.idCardNum = passPortRule;
      }
      if("租房补贴" == this.form.subsidyType){
        delete rules.leaseTerm
      }
      this.formValidator = new Schema(rules);
    },
    validate() {
      this.initFormValidator();
      return new Promise((resolve, reject) => {
        this.formValidator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(async () => {
            resolve({
              ...this.form,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  margin-top: 0.2rem;
  background: #fff;
  .title-gutter {
    width: fit-content;
    margin-left: 0.3rem;
    padding: 0.3rem 0.1rem 0rem 0rem;
    font-size: 0.26rem;
    line-height: 0.26rem;
    border-bottom: 2px solid #3c37d2;
  }
  .fam-btns {
    padding: 0.3rem;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid #eee;
    .btn {
      width: 1.5rem;
      margin-right: 0.15rem;
    }
  }
}
.tip {
  margin: 0.2rem;
}
.idCardNum-button {
  width: 2rem;
  padding: 0 0.15rem;
}
</style>